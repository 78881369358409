<div class="choose-area" id="services">
    <div class="container">
        <div class="section-title text-center">
            <span>Что мы предлагаем</span>
        </div>

        <div class="choose-width pt-100 pb-70">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="choose-card">
                        <i class="flaticon-phone-call"></i>
                        <h3>Ателье</h3>
                        <p>
                            поможет воплотить в жизнь мечты о безупречном стиле.
                            Мы учтем все пожелания, подберем лучшие решения с
                            учетом современных модных тенденций, особенностей
                            фигуры, а также вдохнем вторую жизнь в любимые вещи
                            <br /><br />Создавай свой безупречный гардероб с
                            нами
                        </p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="choose-card">
                        <i class="flaticon-web-page"></i>
                        <h3>RONROC дизайн</h3>
                        <p>
                            молодой русский бренд, объединяющий профессионалов
                            портновского мастерства в творческом желании
                            подчеркнуть статусность женщины, сочетая
                            элегантность и кокетство
                            <br /><br />Уверенность и свобода - твой стиль
                        </p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                    <div class="choose-card">
                        <i class="flaticon-support"></i>
                        <h3>B2B</h3>
                        <p>
                            возможности нашего производства позволяют сегодня
                            выполнять мелко-партийные заказы, тиражом до 1000
                            единиц в месяц. Такое предложение отлично подходит
                            начинающим брендам, шоурумам и дизайнерам. Наш цех
                            укомплектовам современным технологичным швейным
                            оборудованием и штатом первоклассных специалистов.
                            Изделия проходят поэтапный контроль качества на всем
                            пути производства
                            <br /><br />Честные партнерские отношения - залог успеха
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
