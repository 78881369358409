<app-navbar-style-two></app-navbar-style-two>

<div class="inner-banner inner-bg6">
  <div class="container">
      <div class="inner-title text-center">
          <h3>О нас - Швейная фабрика по производству женской одежды RONROC</h3>
          <ul>
              <li><a href="/">RonRoc</a></li>
              <li><i class="bx bx-chevron-right"></i></li>
              <li>О нас</li>
          </ul>
      </div>
  </div>
</div>

<div class="about-area pt-100 pb-70">
  <div class="container">
      <div class="row align-items-center">
          <div class="col-lg-6">
              <div class="about-content">
                  <div class="section-title">
                      <span>Знание и опыт в производстве женской одежды</span>
                      <h2>RONROC - Швейная фабрика по производству женской одежды</h2>
                      <p>
                          Швейная фабрика RONROC — это молодое и динамичное предприятие, специализирующееся на производстве женской одежды. Основанная в 2022 году Татьяной Любовиной, наша фабрика предлагает полный цикл производства, начиная с разработки дизайна и заканчивая выпуском готовых изделий. В основе нашей работы лежат высокие стандарты качества, современные технологии и индивидуальный подход к каждому проекту.
                      </p>
                      <p>
                          Основной деятельностью нашей швейной фабрики является производство женской одежды на заказ, включая сложные и нестандартные модели. Мы используем только высококачественные материалы и фурнитуру, чтобы создавать стильную, удобную и долговечную одежду. Опытные специалисты нашего предприятия тщательно следят за каждым этапом производства, обеспечивая идеальное исполнение каждого изделия.
                      </p>
                      <p>
                          С весны 2023 года фабрика RONROC расширила свои производственные мощности, предлагая услуги пошива не только частным клиентам, но и сотрудничество с дизайнерами и брендами. Наше современное оборудование позволяет выпускать до 1000 единиц женской одежды в месяц, что делает нас надежным партнером для реализации крупных проектов в индустрии моды.
                      </p>
                      <p>
                          Швейная фабрика RONROC — это не просто производство, это место, где каждая идея превращается в стильный и качественный продукт. Мы гордимся тем, что можем предложить вам полный спектр услуг по производству женской одежды на заказ.
                      </p>
                  </div>

                  <div class="about-list">
                      <ul>
                          <li>
                              <i class="flaticon-curve-arrow"></i>
                              Индивидуальный подход к каждому заказу
                          </li>
                          <li>
                              <i class="flaticon-curve-arrow"></i> Высокие стандарты качества производства
                          </li>
                          <li>
                              <i class="flaticon-curve-arrow"></i> Поддержка клиентов на каждом этапе сотрудничества
                          </li>
                      </ul>
                  </div>
              </div>
          </div>

          <div class="col-lg-6">
              <div class="about-img">
                  <img src="assets/img/about-factory.jpeg" alt="Швейная фабрика по производству женской одежды" />
              </div>
          </div>
      </div>
  </div>
</div>

<app-footer-style-two></app-footer-style-two>