<section class="process-area process-bg2">
  <div class="process-into pt-100 pb-70">
      <div class="container">
          <div class="section-title text-center">
              <span>Процесс</span>
              <h2>Смотри <b>Как Это Работает</b></h2>
              <p>От идеи к красоте</p>
          </div>

          <div class="row pt-45">
              <div class="col-lg-4 col-sm-6">
                  <div class="process-card">
                      <i class="flaticon-position"></i>
                      <h3>Изучаем Мировые Тренды</h3>
                      <p>
                          Lorem ipsum dolor sit amet, consetetur adipisicing
                          elit, sed do eiusmod tempor quam voluptatem.
                      </p>
                      <div class="process-number">1</div>
                  </div>
              </div>

              <div class="col-lg-4 col-sm-6">
                  <div class="process-card">
                      <i class="flaticon-to-do-list"></i>
                      <h3>Находим Лучшие Ткани</h3>
                      <p>
                          Lorem ipsum dolor sit amet, consetetur adipisicing
                          elit, sed do eiusmod tempor quam voluptatem.
                      </p>
                      <div class="process-number active">2</div>
                  </div>
              </div>

              <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                  <div class="process-card">
                      <i class="flaticon-box"></i>
                      <h3>Создаем Образ</h3>
                      <p>
                          Lorem ipsum dolor sit amet, consetetur adipisicing
                          elit, sed do eiusmod tempor quam voluptatem.
                      </p>
                      <div class="process-number">3</div>
                  </div>
              </div>
          </div>
      </div>

      <div class="process-line">
          <img src="assets/img/shape/process-line.png" alt="Images" />
      </div>
  </div>
</section>