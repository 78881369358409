<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner inner-bg4">
    <div class="container">
        <div class="inner-banner-title text-center">
            <h3>Property For Sale</h3>
            <p>News pariatur Excepteur sint occaecat iat nulla pariatur Excepteur </p>
        </div>
        
        <div class="banner-list">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-7">
                    <ul class="list">
                        <li><a href="index.html">Home</a></li>
                        <li><i class='bx bx-chevron-right'></i></li>
                        <li>Pages</li>
                        <li><i class='bx bx-chevron-right'></i></li>
                        <li class="active">Blog Details</li>
                    </ul>
                </div>

                <div class="col-lg-6 col-md-5">
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="blog-details-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="blog-article">
                    <div class="article-comment-area">
                        <div class="article-img">
                            <img src="assets/img/blog/blog-details.jpg" alt="Images">
                        </div>

                        <ul class="article-comment">
                            <li>
                                <div class="image">
                                    <img src="assets/img/blog/blog-author.png" alt="Images">
                                </div>

                                <div class="content">
                                    <h3>By Jonslows</h3>
                                    <span>March 19, 2020</span>
                                </div>
                            </li>

                            <li>
                                <div class="content-list">
                                    <h3>Views</h3>
                                    <span>3033</span>
                                </div>
                            </li>

                            <li>
                                <div class="content-list">
                                    <h3>Tags</h3>
                                    <span>Interviews</span>
                                </div>
                            </li>
                        </ul>
                    </div>
            
                    <div class="article-content">
                        <h3>The Property For Sale</h3>
                        <div class="content-text">
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore plicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciuntemo enim ipsam vo</p>
                        </div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore plicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit autquia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                        <blockquote class="blockquote"> 
                            <p>Information Without Cross-media Value. Quickly Maximize Timely Deliverables For Real-time Schemas. Dramatically Maintain Clicks</p>
                        </blockquote>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                        <div class="blog-tag">
                            <ul>
                                <li class="active">Tags:</li>
                                <li><a routerLink="/blog">#Home</a></li>
                                <li><a routerLink="/blog">#RealEstate</a></li>
                                <li><a routerLink="/blog">#FancyHome</a></li>
                                <li><a routerLink="/blog">#Property</a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="article-post">
                        <div class="row">
                            <div class="col-lg-6 col-sm-6">
                                <div class="article-post-share">
                                    <span>Jun 12, 2020 / <a routerLink="/blog-details">Home</a></span>
                                    <a routerLink="/blog-details"><h3>Luxury Property Is Might Be First Choose?</h3></a>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="article-post-share text-align active">
                                    <span>April 19, 2020 / <a routerLink="/blog-details">Interior</a></span>
                                    <a routerLink="/blog-details"><h3 class="active">Real Estate Is Being Came In The Place Of Expectation</h3></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="comments-wrap">
                        <h3 class="title">Comments(02)</h3>

                        <ul>
                            <li>
                                <img src="assets/img/blog/comment-profile1.png" alt="Image">
                                <h3>Devit Killer</h3>
                                <span>Jnauary 12, 2020</span>
                                <p>Software hack from an initial feasibility study, continuing through l implna business you have to be But we know there's a better</p>
                                <a routerLink="/blog-details">Reply</a>
                            </li>

                            <li class="ml-30">
                                <img src="assets/img/blog/comment-profile2.png" alt="Image">
                                <h3>Morah Jein </h3>
                                <span>July 12, 2020</span>
                                <p>Software hack from an initial feasibility study, continuing through l implna business you have to be But we know there's a better</p>
                                <a routerLink="/blog-details">Reply</a>
                            </li>
                        </ul>
                    </div>

                    <div class="comments-form">
                        <div class="contact-form">
                            <span>Reply</span>
                            <h2>Leave a Reply</h2>

                            <div class="agree-label">
                                <input type="checkbox" id="chb1">
                                <label for="chb1">Save my name, email, and website in this browser for the next time I comment.</label>
                            </div>

                            <form id="contactForm">
                                <div class="row">
                                    <div class="col-lg-6 col-sm-6">
                                        <div class="form-group">
                                            <i class='bx bx-user'></i>
                                            <input type="text" name="name" class="form-control" required placeholder="Name*">
                                        </div>
                                    </div>
        
                                    <div class="col-lg-6 col-sm-6">
                                        <div class="form-group">
                                            <i class='bx bx-mail-send'></i>
                                            <input type="email" name="email"  class="form-control" required placeholder="Your Address*">
                                        </div>
                                    </div>
        
                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <i class='bx bx-envelope'></i>
                                            <textarea name="message" class="form-control" id="message" cols="30" rows="8" required placeholder="Your Message"></textarea>
                                        </div>
                                    </div>
        
                                    <div class="col-lg-12 col-md-12">
                                        <button type="submit" class="default-btn border-radius">Post A Comment <i class='bx bx-plus'></i></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="blog-widget-left">
                    <div class="blog-widget ">
                        <h3 class="title">Search</h3>

                        <div class="search-widget">
                            <form class="search-form">
                                <input type="search" class="form-control" placeholder="Search...">
                                <button type="submit"><i class="bx bx-search"></i></button>
                            </form>
                        </div>
                    </div>

                    <div class="blog-widget-profile">
                        <div class="images">
                            <img src="assets/img/blog/blog-profile.png" alt="Images">
                        </div>
                        <div class="content">
                            <h3>Jhon Kelvin</h3>
                            <span>Author Of This Blog</span>
                        </div>
                        <p>I’m Jhon hack from an initial feaity study, continuing through limlna businessack from</p>
                        <ul class="social-link">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                        </ul>
                    </div>

                    <div class="blog-widget">
                        <h3 class="title">Popular Posts</h3>

                        <div class="widget-popular-post">
                            <article class="item">
                                <a routerLink="/blog-details" class="thumb"><span class="full-image cover bg1" role="img"></span></a>
                                <div class="info">
                                    <time datetime="2020-01-12">Jnauary 12, 2020</time>
                                    <h4 class="title-text"><a routerLink="/blog-details">Real Estate Is Being</a></h4>
                                    <p>Jhon hack from an iitial feaity study</p>
                                </div>
                            </article>

                            <article class="item">
                                <a routerLink="/blog-details" class="thumb"><span class="full-image cover bg2" role="img"></span></a>
                                <div class="info">
                                    <time datetime="2020-06-30">June 30, 2020</time>
                                    <h4 class="title-text"><a routerLink="/blog-details">Place Of Expectation</a></h4>
                                    <p>Jhon hack from an iitial feaity study</p>
                                </div>
                            </article>

                            <article class="item">
                                <a routerLink="/blog-details" class="thumb"><span class="full-image cover bg3" role="img"></span></a>
                                <div class="info">
                                    <span>April 09, 2020</span>
                                    <h4 class="title-text"><a routerLink="/blog-details">Came In The Place </a> </h4>
                                    <p>Jhon hack from an iitial feaity study</p>
                                </div>
                            </article>
                        </div>
                    </div>

                    <div class="blog-widget">
                        <h3 class="title">News Tags</h3>

                        <ul class="blog-widget-tag">
                            <li><a routerLink="/blog">#Home</a></li>
                            <li><a routerLink="/blog"><b>#Residence</b></a></li>
                            <li><a routerLink="/blog">#Realestate</a></li>
                            <li><a routerLink="/blog"><b>#Residence</b></a></li>
                            <li><a routerLink="/blog">#Fancyhouse</a></li>
                            <li><a routerLink="/blog">#Realestate</a></li>
                        </ul>
                    </div>

                    <div class="blog-widget">
                        <h3 class="title">Categories</h3>

                        <div class="widget_categories">
                            <ul>
                                <li><a routerLink="/blog">Outdoor <span>(1)</span></a></li>
                                <li><a routerLink="/blog">Interviews <span>(4)</span></a></li>
                                <li><a routerLink="/blog">Camping <span>(3)</span></a></li>
                                <li><a routerLink="/blog">Lifestyle <span>(2)</span></a></li>
                                <li><a routerLink="/blog">Outdoor <span>(6)</span></a></li>
                                <li><a routerLink="/blog">Travel <span>(18)</span></a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="blog-widget">
                        <h3 class="title">Twitter</h3>

                        <div class="widget-post-into">
                            <div class="blog-widget-post">
                                <span>@Pilar - 1 day ago</span>
                                <a routerLink="/"><h3>Renovations that Add the Most Resale Value to your Home</h3> </a>
                            </div>

                            <div class="blog-widget-post">
                                <span>@Pilar - 31 December</span>
                                <a routerLink="/"><h3>Most Resale Renovations that Add the Value to your Home</h3> </a>
                            </div>

                            <div class="blog-widget-post">
                                <span>@Pilar - 14 July</span>
                                <a routerLink="/"><h3> Add the Most Resale that Value to your Home</h3> </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>