<section class="city-area-two ptb-100">
  <div class="container">
      <div class="row align-items-center">
          <div class="col-lg-8">
              <div class="section-title">
                  <span>Хиты</span>
                  <h2>Самые продаваемые товары сезона</h2>
                  <p>Сделай свой выбор</p>
              </div>
          </div>

          <div class="col-lg-4">
              <div class="city-btn">
                  <a routerLink="/" class="default-btn"
                      >Показать все <i class="bx bx-plus"></i
                  ></a>
              </div>
          </div>
      </div>

      <div class="row pt-45">
          <div *ngFor="let item of appService.cardHit" class="col-lg-4 col-md-6">
              <div class="place-tag">
                  <a routerLink="/"
                      ><img src="{{ item.img }}" alt="Images"
                  /></a>
                  <div class="content">
                      <a routerLink="/"
                          ><h3>{{ item.naim }}</h3></a
                      >
                  </div>
              </div>
          </div>

          <div class="col-lg-7 col-md-6">
              <div class="city-card">
                  <a routerLink="/"
                      ><img
                          src="assets/img/city-area/city-area8.jpg"
                          alt="Images"
                  /></a>
                  <div class="content">
                      <a routerLink="/"><h3>United Kingdom</h3></a>
                      <span>30 Locations</span>
                  </div>
              </div>
          </div>

          <div class="col-lg-5 col-md-6 offset-md-3 offset-lg-0">
              <div class="city-card">
                  <a routerLink="/"
                      ><img
                          src="assets/img/city-area/city-area9.jpg"
                          alt="Images"
                  /></a>
                  <div class="content">
                      <a routerLink="/"><h3>Turkey</h3></a>
                      <span>34 Locations</span>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>