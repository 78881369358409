<section class="process-area process-bg2" id="process-area">
    <div class="process-into pt-100 pb-70">
        <div class="container">
            <div class="section-title text-center">
                <span>Процесс</span>
                <h2>Наши <b>Преимущества</b></h2>
                <p>
                    Каждый день с любовью к труду мы создаем, эспериментируем и
                    развиваемся, чтобы сделать наших клиентов счастливыми
                </p>
            </div>

            <div class="row pt-45">
                <div class="col-lg-4 col-sm-6">
                    <div>
                        <h4>Производство</h4>
                        <p>
                            Cобственное производство с современным оборудованием
                        </p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div>
                        <div>
                            <h4>Персонал</h4>
                            <p>
                                Опытный персонал готовый оказать консультацию,
                                помочь с выбором и воплотить идею в изделие
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                    <div>
                        <div>
                            <h4>Сервис</h4>
                            <p>
                                Индивидуальный подход к каждому клиенту, честные
                                партнерские отношения и уютная атмосфера
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="process-line">
            <img src="assets/img/shape/process-line.png" alt="Images" />
        </div>
    </div>
</section>

<!-- 
<section class="testimonial-area-two2 pt-100 pb-70">
    <div class="container-fluid1">
        <div class="section-title1 text-center">
            <span>Что мы предлагаем</span>
            <h2>Наши <b>Преимущества</b></h2>
        </div>

        <div class="testimonial-slider-two owl-carousel owl-theme">
            <div class="testimonial-item testimonial-item-bg2">
                <h1>1</h1>
                <p>Собственное производство с современным оборудованием</p>
            </div>
            <div class="testimonial-item testimonial-item-bg2">
                <h1>2</h1>
                <p>
                    Опытный персонал готовый оказать консультацию, помочь с
                    выбором и воплотить идею в изделие
                </p>
            </div>
            <div class="testimonial-item testimonial-item-bg2">
                <h1>3</h1>
                <p>
                    Индивидуальный подход к каждому клиенту, честные партнерские
                    отношения и уютная атмосфера
                </p>
            </div>
        </div>
    </div>
</section>
 -->
