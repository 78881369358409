import { Component, OnInit } from "@angular/core";
import { AppService } from "src/app/app.service";
import { HttpClient } from "@angular/common/http";
import { Meta } from "@angular/platform-browser";

@Component({
    selector: "app-home-two",
    templateUrl: "./home-two.component.html",
    styleUrls: ["./home-two.component.scss"],
})
export class HomeTwoComponent implements OnInit {
    constructor(public appService: AppService, private http: HttpClient, private meta: Meta) {
        this.meta.addTag({ name: 'title', content: 'Ателье по Пошиву Одежды – Индивидуальный Подход и Идеальная Посадка' });
        this.meta.addTag({ name: 'description', content: 'Наше ателье по пошиву одежды предлагает уникальные решения для вашего гардероба. Индивидуальный подход, качественные материалы и идеальная посадка – создайте одежду, которая подчеркнет вашу индивидуальность. Закажите пошив сейчас и наслаждайтесь эксклюзивными нарядами, созданными специально для вас!' });
    }

    ngOnInit(): void {
        this.appService.categoryThis = 1;
    }
}
