<app-navbar-style-two></app-navbar-style-two>

<div class="inner-banner inner-bg6">
    <div class="container">
        <div class="inner-title text-center">
            <h3>О нас - Ателье по ремонту и пошиву одежды RONROC</h3>
            <ul>
                <li><a href="/">RonRoc</a></li>
                <li><i class="bx bx-chevron-right"></i></li>
                <li>О нас</li>
            </ul>
        </div>
    </div>
</div>

<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-content">
                    <div class="section-title">
                        <span>Знание и мастерство</span>
                        <h2>RONROC - Ваше Ателье по ремонту и пошиву одежды в Новочеркасске</h2>
                        <p>
                            Ателье RONROC — это молодое и динамичное швейное арт-пространство, специализирующееся на пошиве одежды на заказ и ремонте одежды. Основанное в 2022 году Татьяной Любовиной, наше ателье предлагает уникальные услуги, которые подчеркнут вашу индивидуальность и статус. В основе нашей философии лежат элегантность, комфорт и эстетика, что делает каждое изделие уникальным и идеально подходящим для вас.
                        </p>
                        <p>
                            Наши услуги включают не только пошив одежды на заказ, но и ремонт одежды любого уровня сложности. Внимание к деталям, использование качественных материалов и фурнитуры, а также индивидуальный подход к каждому клиенту — вот что делает наше ателье выбором номер один для многих.
                        </p>
                        <p>
                            С весны 2023 года RONROC расширяет свои услуги, предлагая не только индивидуальный пошив и ремонт одежды, но и сотрудничество с молодыми дизайнерами. Наше современное производство позволяет выполнять заказы на пошив до 100 единиц продукции в месяц. Мы беремся за сложные проекты и всегда гарантируем высокое качество исполнения.
                        </p>
                        <p>
                            Ателье RONROC — это место, где ваше желание выразить себя через уникальную и качественную одежду становится реальностью. Мы гордимся тем, что можем предложить вам полный спектр услуг по ремонту и пошиву одежды.
                        </p>
                    </div>

                    <div class="about-list">
                        <ul>
                            <li>
                                <i class="flaticon-curve-arrow"></i>
                                Индивидуальный подход к ремонту и пошиву одежды
                            </li>
                            <li>
                                <i class="flaticon-curve-arrow"></i> Высокое качество материалов и исполнения
                            </li>
                            <li>
                                <i class="flaticon-curve-arrow"></i> Поддержка клиентов на каждом этапе заказа
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-img">
                    <img src="assets/img/studioAbout.jpeg" alt="Ателье по ремонту и пошиву одежды" />
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>