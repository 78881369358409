<app-navbar-style-two></app-navbar-style-two>

<div class="error-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="error-content">
                <img src="assets/img/logo/logo2.png" alt="Images">
                <h1>4 <span>0</span> 4</h1>
                <h3>Oops! Страница не найдена</h3>
                <p>Страница, которую вы ищете, могла быть удалена из-за изменения ее названия или временно недоступна.</p>
                <a routerLink="/" class="default-btn">Вернуться на Главную страницу</a>
            </div>
        </div>
    </div>
</div>