<section class="category-area pt-100 pb-70">
  <div class="container">
      <div class="section-title">
          <span>Категории</span>
          <h2>Поиск <b>По Категориям</b></h2>
      </div>

      <div class="row pt-45">
          <div class="col-lg-2 col-sm-6" *ngFor="let item of appService.categorySubFilter">
              <div class="category-box" >
                  <a routerLink="/category/{{ item.idMain }}/{{item.id}}"
                      ><i class="{{ item.icon }}"></i
                  ></a>
                  <a routerLink="/category/{{ item.idMain }}/{{item.id}}"
                      ><h3>{{ item.naim }}</h3></a
                  >
              </div>
          </div>
      </div>
  </div>
</section>
