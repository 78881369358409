<app-navbar-style-two></app-navbar-style-two>

<div class="inner-banner inner-bg6">
    <div class="container">
        <div class="inner-title text-center">
            <h3>О нас - Женская одежда оптом от RONROC</h3>
            <ul>
                <li><a href="/">RonRoc</a></li>
                <li><i class="bx bx-chevron-right"></i></li>
                <li>Каталог новинок</li>
            </ul>
        </div>
    </div>
</div>

<div class="product-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6"  *ngFor="let item of cardNew$ | async">
                <div class="product-card  place-list-item">
                    <div class="images">
                    <a href="https://www.ozon.ru/product/{{ item.link }}" class="images-list"
                    ><img src="assets/img/catalog/{{ item.img }}.jpg" alt="{{ item.naim }}"/></a>
                    <div class="product-content">
                        <ul class="rating">
                            <li>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </li>
                        </ul>
                        <a href="https://www.ronroc.ru/product/{{ item.link }}"><h3>{{ item.naim }}</h3></a>
                    </div>
                  </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-content">
                    <div class="section-title">
                        <span>Знание и опыт в сфере оптовой торговли</span>
                        <h2>RONROC - Надежный поставщик женской одежды оптом</h2>
                        <p>
                            Компания RONROC специализируется на оптовой торговле женской одеждой, предлагая широкий ассортимент стильных и качественных моделей. Основанное в 2022 году, наше предприятие быстро зарекомендовало себя как надежный партнер для магазинов и розничных продавцов, заинтересованных в закупке женской одежды оптом.
                        </p>
                        <p>
                            В нашем каталоге представлена женская одежда, сочетающая последние модные тенденции и высокие стандарты качества. Мы предлагаем гибкие условия сотрудничества для оптовых покупателей, гарантируя конкурентные цены и своевременные поставки. Наша продукция включает в себя повседневную одежду, деловые костюмы, вечерние наряды и многое другое.
                        </p>
                        <p>
                            RONROC не только производит женскую одежду оптом, но и предлагает индивидуальный подход к каждому клиенту. Мы понимаем важность качества и предлагаем одежду, которая отвечает всем требованиям современного рынка. Наши партнеры могут рассчитывать на надежность и высокий уровень сервиса, что делает сотрудничество с нами максимально удобным и выгодным.
                        </p>
                        <p>
                            Если вы ищете надежного поставщика женской одежды оптом, RONROC — ваш идеальный выбор. Мы с радостью поможем вам расширить ассортимент вашего магазина качественной и стильной одеждой, которая обязательно понравится вашим клиентам.
                        </p>
                    </div>

                    <div class="about-list">
                        <ul>
                            <li>
                                <i class="flaticon-curve-arrow"></i>
                                Широкий ассортимент женской одежды оптом
                            </li>
                            <li>
                                <i class="flaticon-curve-arrow"></i> Высокое качество продукции
                            </li>
                            <li>
                                <i class="flaticon-curve-arrow"></i> Гибкие условия сотрудничества
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-img">
                    <img src="assets/img/catalog-about.jpeg" alt="Женская одежда оптом" />
                </div>
            </div>
        </div>
    </div>

<app-footer-style-two></app-footer-style-two>