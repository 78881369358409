<app-navbar-style-two></app-navbar-style-two>

<div class="inner-banner inner-bg6">
    <div class="container">
        <div class="inner-title text-center">
            <h3>О нас</h3>
            <ul>
                <li><a href="/">RonRoc</a></li>
                <li><i class="bx bx-chevron-right"></i></li>
                <li>О нас</li>
            </ul>
        </div>
    </div>
</div>

<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-content">
                    <div class="section-title">
                        <span>Знание</span>
                        <h2>RONROC - это молодое швейное арт-пространство</h2>
                        <p>
                            созданное в 2022 году идейным мыслителем, горящим
                            сердцем и главой Татьяной Любовиной: «Когда думаю о
                            комфорте, достатке, красоте и целостности личности,
                            то представляю себе уверенную и свободную духом
                            женщину современности. Женщина, которая своей
                            легкостью, мудростью и любовью создаёт уют в семье и
                            строит успешную карьеру». Так родилась наша миссия:
                            подчеркнуть статусность женщины, сочетая
                            элегантность и кокетство. Сформировались ценности:
                            этика и эстетика должны совпадать и находить
                            отражение в стиле. Для которого комфорт является
                            важной составляющей.
                        </p>
                        <p>
                            Создавая изделия и общаясь с друзьями бренда -
                            нашими клиентами, отметили стремление каждого к
                            удовольствию быть собой и трудности с подбором
                            одежды по индивидуальным предпочтениям и меркам. Мы
                            сразу решили помочь! Ателье RONROC - это сочетание
                            уважения и внимательности к клиенту, традиционных
                            технологий и процессов, опыта, мастерства и
                            тщательности портных. Пошив одежды на заказ с учётом
                            всех особенностей фигуры обеспечивает идеальную
                            посадку и комфорт. Правильно подобранные ткани и
                            фурнитура, а также работа опытных мастеров создают
                            безупречный внешний вид. Каждое изделие нашего
                            ателье уникально, не повторяется и призвано
                            подчеркнуть индивидуальность клиента.
                        </p>
                        <p>
                            Развивая свой творческий путь и расширяя компетенции
                            швейного ремесла мы постоянно ищем пути развития и
                            весной 2023 года был задан вектор на построение
                            современного производства для оказания услуг пошива
                            коллегам по цеху - молодым дизайнерам. Наше
                            производство позволяет выпускать до 1000 единиц в
                            месяц. Мы умеем делать сложный продукт с
                            продуманными деталями и готовы помогать в
                            качественном создании интересного продукта. Поэтому
                            мы берем не все заказы и оставляем за собой право
                            выбора о принятии участия в сторонних проектах.
                        </p>
                    </div>

                    <div class="about-list">
                        <ul>
                            <li>
                                <i class="flaticon-curve-arrow"></i>
                                Индивидуальный подход
                            </li>
                            <li>
                                <i class="flaticon-curve-arrow"></i> Высокое
                                качество
                            </li>
                            <li>
                                <i class="flaticon-curve-arrow"></i> Клиентская
                                поддержка
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-img">
                    <img src="assets/img/about-img.jpg" alt="image" />
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>
