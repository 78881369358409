<section class="testimonial-area-two pt-100 pb-70">
  <div class="container-fluid">
      <div class="section-title text-center">
          <span>Мнения</span>
          <h2>Что Говорят <b>О Нас</b></h2>
      </div>

      <div class="testimonial-slider-two owl-carousel owl-theme">
          <div
              class="testimonial-item testimonial-item-bg2"
              *ngFor="let item of appService.reviews"
          >
              <h3>{{ item.name }}</h3>
              <span>Arbon Restaurant</span>
              <p>{{ item.reviews }}</p>
              <ul class="rating">
                  <li><i class="bx bxs-star"></i></li>
                  <li><i class="bx bxs-star"></i></li>
                  <li><i class="bx bxs-star"></i></li>
                  <li><i class="bx bxs-star"></i></li>
                  <li><i class="bx bxs-star"></i></li>
              </ul>
              <div class="testimonial-top">
                  <img
                      src="assets/img/testimonial/client1.png"
                      alt="Images"
                  />
              </div>
          </div>
      </div>
  </div>
</section>