<div class="video-area video-area-bg">
  <div class="container">
      <div class="video-content video-ptb">
          <h2>Are You Ready To Start Your Journey?</h2>
          <a
              href="https://www.youtube.com/watch?v=07d2dXHYb94&t=6s"
              class="play-btn"
              ><i class="bx bx-play"></i
          ></a>
      </div>
  </div>
</div>