<section class="place-list-two pb-70">
    <div class="container">
        <div class="section-title">
            <span>Новинки</span>
            <h2>Список <b>Последних Новинок</b></h2>
            <p>Тренды этого сезона</p>
        </div>
    </div>

    <div class="container-fluid">
        <div class="place-slider-two owl-carousel owl-theme pt-45">
            <div class="place-list-item" *ngFor="let item of cardNew$ | async">
                <div class="images">
                    <a href="https://www.ozon.ru/product/{{ item.link }}" class="images-list"
                        ><img src="assets/img/catalog/{{ item.img }}.jpg" alt="Images"
                    /></a>
                </div>
                <div class="content">
                    <a href="https://www.ronroc.ru/product/{{ item.link }}"
                        ><h3>{{ item.naim }}</h3></a
                    >
                </div>
            </div>
        </div>
    </div>
</section>
