import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { PreloaderComponent } from "./components/common/preloader/preloader.component";
import { HomeOneComponent } from "./components/pages/home-one/home-one.component";
import { HomeTwoComponent } from "./components/pages/home-two/home-two.component";
import { HomeThreeComponent } from "./components/pages/home-three/home-three.component";
import { NavbarStyleOneComponent } from "./components/common/navbar-style-one/navbar-style-one.component";
import { NavbarStyleTwoComponent } from "./components/common/navbar-style-two/navbar-style-two.component";
import { FooterStyleOneComponent } from "./components/common/footer-style-one/footer-style-one.component";
import { FooterStyleTwoComponent } from "./components/common/footer-style-two/footer-style-two.component";
import { AboutComponent } from "./components/pages/about/about.component";
import { TeamComponent } from "./components/pages/team/team.component";
import { PricingComponent } from "./components/pages/pricing/pricing.component";
import { CategoryComponent } from "./components/pages/category/category.component";
import { FaqComponent } from "./components/pages/faq/faq.component";
import { TestimonialsComponent } from "./components/pages/testimonials/testimonials.component";
import { ErrorComponent } from "./components/pages/error/error.component";
import { LoginRegisterComponent } from "./components/pages/login-register/login-register.component";
import { TermsConditionComponent } from "./components/pages/terms-condition/terms-condition.component";
import { PrivacyPolicyComponent } from "./components/pages/privacy-policy/privacy-policy.component";
import { ComingSoonComponent } from "./components/pages/coming-soon/coming-soon.component";
import { ShopComponent } from "./components/pages/shop/shop.component";
import { CartComponent } from "./components/pages/cart/cart.component";
import { CheckoutComponent } from "./components/pages/checkout/checkout.component";
import { ProductsDetailsComponent } from "./components/pages/products-details/products-details.component";
import { ListingComponent } from "./components/pages/listing/listing.component";
import { ListingDetailsComponent } from "./components/pages/listing-details/listing-details.component";
import { BlogComponent } from "./components/pages/blog/blog.component";
import { BlogDetailsComponent } from "./components/pages/blog-details/blog-details.component";
import { ContactComponent } from "./components/pages/contact/contact.component";
import { CategoryAreaComponent } from "./components/common/category-area/category-area.component";
import { PlaceListComponent } from "./components/common/place-list/place-list.component";
import { ProcessAreaComponent } from "./components/common/process-area/process-area.component";
import { PlaceAreaComponent } from "./components/common/place-area/place-area.component";
import { VideoAreaComponent } from "./components/common/video-area/video-area.component";
import { CityAreaComponent } from "./components/common/city-area/city-area.component";
import { ReviewsAreaComponent } from "./components/common/reviews-area/reviews-area.component";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { AdvantagesComponent } from './components/common/advantages/advantages.component';
import { ServicesComponent } from './components/common/services/services.component';
import { StudioComponent } from './components/pages/studio/studio.component';
import { FactoryComponent } from './components/pages/factory/factory.component';
import { ShopAboutComponent } from './components/pages/shop-about/shop-about.component';

@NgModule({
    declarations: [
        AppComponent,
        PreloaderComponent,
        HomeOneComponent,
        HomeTwoComponent,
        HomeThreeComponent,
        NavbarStyleOneComponent,
        NavbarStyleTwoComponent,
        FooterStyleOneComponent,
        FooterStyleTwoComponent,
        AboutComponent,
        TeamComponent,
        PricingComponent,
        CategoryComponent,
        FaqComponent,
        TestimonialsComponent,
        ErrorComponent,
        LoginRegisterComponent,
        TermsConditionComponent,
        PrivacyPolicyComponent,
        ComingSoonComponent,
        ShopComponent,
        CartComponent,
        CheckoutComponent,
        ProductsDetailsComponent,
        ListingComponent,
        ListingDetailsComponent,
        BlogComponent,
        BlogDetailsComponent,
        ContactComponent,
        CategoryAreaComponent,
        PlaceListComponent,
        ProcessAreaComponent,
        PlaceAreaComponent,
        VideoAreaComponent,
        CityAreaComponent,
        ReviewsAreaComponent,
        AdvantagesComponent,
        ServicesComponent,
        StudioComponent,
        FactoryComponent,
        ShopAboutComponent,
    ],
    imports: [BrowserModule, AppRoutingModule, HttpClientModule, FormsModule],
    providers: [],
    bootstrap: [AppComponent],
})
export class AppModule {}
