<footer class="footer-area footer-bg2">
    <div class="footer-middle pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="footer-widget">
                        <a routerLink="/" class="logo"
                            ><img
                                src="assets/img/logo_black_small.png"
                                alt="Logo"
                        /></a>

                        <ul class="footer-contact-list">
                            <li>Россия, г.Новочеркасск,</li>
                            <!-- <li>пер. Цимлянский 1В</li> -->
                            <li>
                                <span>Телефон :</span>
                                <a href="tel:+79508684766"> +7 950 868 47 66</a>
                            </li>
                            <li>
                                <span>Email :</span>
                                <a href="mailto:sale@ronroc.ru">sale@ronroc.ru</a>
                            </li>
                        </ul>
                        <ul class="social-link">
                            <li>
                                <a
                                    href="https://vk.com/public211447112"
                                    target="_blank"
                                    ><i class="bx bxl-vk"></i
                                ></a>
                            </li>
                            <li>
                                <a
                                    href="https://t.me/RonrocSale"
                                    target="_blank"
                                    ><i class="bx bxl-telegram"></i
                                ></a>
                            </li>
                            <li>
                                <a
                                    href="https://wa.me/+79508684766"
                                    target="_blank"
                                    ><i class="bx bxl-whatsapp"></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="footer-widget pl-5">
                        <h3>Ссылки</h3>

                        <ul class="footer-list">
                            <li><a routerLink="/about">О нас</a></li>
                            <li><a routerLink="/contact">Контакты</a></li>
                            <li><a routerLink="/ателье-по-пошиву-одежды">Ателье по ремонту и пошиву одежды</a></li>
                            <li><a routerLink="/швейная-фабрика">Швейная фабрика</a></li>
                            <li><a routerLink="/магазин-женской-одежды">Магазин женской одежды</a></li>
                            <li><a routerLink="/женская-одежда-оптом">Одежда оптом</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
