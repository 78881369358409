<app-navbar-style-two></app-navbar-style-two>
<div class="inner-banner inner-bg6">
  <div class="container">
      <div class="inner-title text-center">
          <h3>О нас - Оффлайн магазин женской одежды в Новочеркасске</h3>
          <ul>
              <li><a href="/">RonRoc</a></li>
              <li><i class="bx bx-chevron-right"></i></li>
              <li>О нас</li>
          </ul>
      </div>
  </div>
</div>

<div class="about-area pt-100 pb-70">
  <div class="container">
      <div class="row align-items-center">
          <div class="col-lg-6">
              <div class="about-content">
                  <div class="section-title">
                      <span>Знание и опыт в мире моды</span>
                      <h2>RONROC - Оффлайн магазин женской одежды в Новочеркасске</h2>
                      <p>
                          Магазин женской одежды RONROC — это уникальное место в самом сердце Новочеркасска, где каждая женщина может найти стильную и качественную одежду. Основанный в 2022 году, наш магазин предлагает широкий ассортимент женской одежды, сочетающей современные модные тенденции и безупречное качество.
                      </p>
                      <p>
                          В нашем оффлайн магазине вы найдете коллекции женской одежды, разработанные с учетом последних трендов. Мы предлагаем наряды для любого случая: от повседневных образов до вечерних платьев. Весь ассортимент подбирается с вниманием к деталям, чтобы каждая покупка радовала наших клиенток.
                      </p>
                      <p>
                          Наш магазин женской одежды в Новочеркасске также предлагает индивидуальные консультации по стилю и подбору гардероба. Мы стремимся создать атмосферу уюта и комфорта, чтобы каждая покупательница чувствовала себя особенной. Мы гордимся тем, что можем предложить жительницам Новочеркасска не только модную одежду, но и уникальный опыт шопинга.
                      </p>
                      <p>
                          RONROC — это не просто магазин, это место, где ваша индивидуальность находит своё отражение в каждом выбранном наряде. Мы с нетерпением ждем вас в нашем магазине женской одежды в Новочеркасске, чтобы помочь вам создать ваш идеальный образ.
                      </p>
                  </div>

                  <div class="about-list">
                      <ul>
                          <li>
                              <i class="flaticon-curve-arrow"></i>
                              Широкий ассортимент женской одежды
                          </li>
                          <li>
                              <i class="flaticon-curve-arrow"></i> Индивидуальные консультации по стилю
                          </li>
                          <li>
                              <i class="flaticon-curve-arrow"></i> Уютная атмосфера для комфортного шопинга
                          </li>
                      </ul>
                  </div>
              </div>
          </div>

          <div class="col-lg-6">
              <div class="about-img">
                  <img src="assets/img/about-img.jpg" alt="Магазин женской одежды в Новочеркасске" />
              </div>
          </div>
      </div>
  </div>
</div>

<app-footer-style-two></app-footer-style-two>