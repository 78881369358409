<div class="place-area pt-100 pb-70">
  <div class="container">
      <div class="row align-items-center">
          <div class="col-lg-7 col-md-6">
              <div class="section-title mb-45">
                  <span>Desire Places</span>
                  <h2>Most <b>Popular Places</b></h2>
              </div>
          </div>

          <div class="col-lg-5 col-md-6">
              <div class="place-btn">
                  <a routerLink="/listing" class="default-btn"
                      >Check out all places <i class="bx bx-plus"></i
                  ></a>
              </div>
          </div>
      </div>

      <div class="row">
          <div class="col-lg-4 col-md-6">
              <div class="place-card">
                  <a routerLink="/listing-details" class="place-images"
                      ><img
                          src="assets/img/place-area/place-area1.jpg"
                          alt="Images"
                  /></a>

                  <div class="rating">
                      <ul>
                          <li><a routerLink="/">4.9</a></li>
                          <li>
                              <span>23 Review</span>
                              <i class="bx bxs-star"></i>
                              <i class="bx bxs-star"></i>
                              <i class="bx bxs-star"></i>
                              <i class="bx bxs-star i-color"></i>
                              <i class="bx bxs-star i-color"></i>
                          </li>
                      </ul>
                  </div>

                  <div class="status-tag bg-dark-orange">
                      <a routerLink="/listing-details"><h3>Closed Now</h3></a>
                  </div>

                  <div class="content">
                      <div class="content-profile">
                          <img
                              src="assets/img/place-area/place-area-profile.png"
                              alt="Images"
                          />
                          <h3>By, Lowis Jelda</h3>
                      </div>
                      <span
                          ><i class="flaticon-cursor"></i> Dongo 184 Crono,
                          Canada</span
                      >
                      <a routerLink="/listing-details"
                          ><h3>Denisto Centin Restaurant</h3></a
                      >
                      <p>
                          Lorem ipsum dolor sit amet, consectetur quam quaerat
                          voluptatem.
                      </p>
                      <div class="content-tag">
                          <ul>
                              <li>
                                  <a routerLink="/"
                                      ><i class="flaticon-place"></i
                                  ></a>
                              </li>
                              <li>
                                  <a routerLink="/"
                                      ><i class="flaticon-like"></i
                                  ></a>
                              </li>
                              <li>
                                  <a routerLink="/"
                                      ><i class="flaticon-workflow"></i
                                  ></a>
                              </li>
                          </ul>
                          <h3 class="price"><a href="#">$560-890</a></h3>
                      </div>
                  </div>
              </div>
          </div>

          <div class="col-lg-4 col-md-6">
              <div class="place-card">
                  <a routerLink="/listing-details" class="place-images"
                      ><img
                          src="assets/img/place-area/place-area2.jpg"
                          alt="Images"
                  /></a>

                  <div class="rating">
                      <ul>
                          <li><a routerLink="/">5.0</a></li>
                          <li>
                              <span>30 Review</span>
                              <i class="bx bxs-star"></i>
                              <i class="bx bxs-star"></i>
                              <i class="bx bxs-star"></i>
                              <i class="bx bxs-star"></i>
                              <i class="bx bxs-star"></i>
                          </li>
                      </ul>
                  </div>

                  <div class="status-tag bg-color-blue">
                      <a routerLink="/listing-details"><h3>Open Now</h3></a>
                  </div>

                  <div class="content">
                      <div class="content-profile">
                          <img
                              src="assets/img/place-area/place-area-profile2.png"
                              alt="Images"
                          />
                          <h3>By, Austin Deli</h3>
                      </div>
                      <span
                          ><i class="flaticon-cursor"></i> 40 Square Plaza,
                          NJ, USA</span
                      >
                      <a routerLink="/listing-details"
                          ><h3>Iconic Cafe in Ontario</h3></a
                      >
                      <p>
                          Lorem ipsum dolor sit amet, consectetur quam quaerat
                          voluptatem.
                      </p>
                      <div class="content-tag">
                          <ul>
                              <li>
                                  <a routerLink="/"
                                      ><i class="flaticon-place"></i
                                  ></a>
                              </li>
                              <li>
                                  <a routerLink="/"
                                      ><i class="flaticon-like"></i
                                  ></a>
                              </li>
                              <li>
                                  <a routerLink="/"
                                      ><i class="flaticon-workflow"></i
                                  ></a>
                              </li>
                          </ul>
                          <h3 class="price"><a href="#">$500-700</a></h3>
                      </div>
                  </div>
              </div>
          </div>

          <div class="col-lg-4 col-md-6">
              <div class="place-card">
                  <a routerLink="/listing-details" class="place-images"
                      ><img
                          src="assets/img/place-area/place-area3.jpg"
                          alt="Images"
                  /></a>

                  <div class="rating">
                      <ul>
                          <li><a routerLink="/">4.9</a></li>
                          <li>
                              <span>23 Review</span>
                              <i class="bx bxs-star"></i>
                              <i class="bx bxs-star"></i>
                              <i class="bx bxs-star"></i>
                              <i class="bx bxs-star i-color"></i>
                              <i class="bx bxs-star i-color"></i>
                          </li>
                      </ul>
                  </div>

                  <div class="status-tag bg-color-heliotrope">
                      <a routerLink="/listing-details"><h3>Open Now</h3></a>
                  </div>

                  <div class="content">
                      <div class="content-profile">
                          <img
                              src="assets/img/place-area/place-area-profile3.png"
                              alt="Images"
                          />
                          <h3>By, Polin Osto</h3>
                      </div>
                      <span
                          ><i class="flaticon-cursor"></i> 34-42 Montgomery St
                          , NY, USA</span
                      >
                      <a routerLink="/listing-details"
                          ><h3>Strong body Gym</h3></a
                      >
                      <p>
                          Lorem ipsum dolor sit amet, consectetur quam quaerat
                          voluptatem.
                      </p>
                      <div class="content-tag">
                          <ul>
                              <li>
                                  <a routerLink="/"
                                      ><i class="flaticon-place"></i
                                  ></a>
                              </li>
                              <li>
                                  <a routerLink="/"
                                      ><i class="flaticon-like"></i
                                  ></a>
                              </li>
                              <li>
                                  <a routerLink="/"
                                      ><i class="flaticon-workflow"></i
                                  ></a>
                              </li>
                          </ul>
                          <h3 class="price">
                              <a routerLink="/listing-details">$400-800</a>
                          </h3>
                      </div>
                  </div>
              </div>
          </div>

          <div class="col-lg-4 col-md-6">
              <div class="place-card">
                  <a routerLink="/listing-details" class="place-images"
                      ><img
                          src="assets/img/place-area/place-area4.jpg"
                          alt="Images"
                  /></a>

                  <div class="rating">
                      <ul>
                          <li><a routerLink="/">4.8</a></li>
                          <li>
                              <span>16 Review</span>
                              <i class="bx bxs-star"></i>
                              <i class="bx bxs-star"></i>
                              <i class="bx bxs-star i-color"></i>
                              <i class="bx bxs-star i-color"></i>
                              <i class="bx bxs-star i-color"></i>
                          </li>
                      </ul>
                  </div>

                  <div class="status-tag bg-dark-orange">
                      <a routerLink="/listing-details"><h3>Closed Now</h3></a>
                  </div>

                  <div class="content">
                      <div class="content-profile">
                          <img
                              src="assets/img/place-area/place-area-profile4.png"
                              alt="Images"
                          />
                          <h3>By, Debit Jhon</h3>
                      </div>
                      <span
                          ><i class="flaticon-cursor"></i> 27th Brooklyn New
                          York, USA</span
                      >
                      <a routerLink="/listing-details"
                          ><h3>Family Convenience Store</h3></a
                      >
                      <p>
                          Lorem ipsum dolor sit amet, consectetur quam quaerat
                          voluptatem.
                      </p>
                      <div class="content-tag">
                          <ul>
                              <li>
                                  <a routerLink="/"
                                      ><i class="flaticon-place"></i
                                  ></a>
                              </li>
                              <li>
                                  <a routerLink="/"
                                      ><i class="flaticon-like"></i
                                  ></a>
                              </li>
                              <li>
                                  <a routerLink="/"
                                      ><i class="flaticon-workflow"></i
                                  ></a>
                              </li>
                          </ul>
                          <h3 class="price"><a href="#">$560-890</a></h3>
                      </div>
                  </div>
              </div>
          </div>

          <div class="col-lg-4 col-md-6">
              <div class="place-card">
                  <a routerLink="/listing-details" class="place-images"
                      ><img
                          src="assets/img/place-area/place-area5.jpg"
                          alt="Images"
                  /></a>

                  <div class="rating">
                      <ul>
                          <li><a routerLink="/">5.0</a></li>
                          <li>
                              <span>16 Review</span>
                              <i class="bx bxs-star"></i>
                              <i class="bx bxs-star"></i>
                              <i class="bx bxs-star"></i>
                              <i class="bx bxs-star"></i>
                              <i class="bx bxs-star"></i>
                          </li>
                      </ul>
                  </div>

                  <div class="status-tag bg-color-blue">
                      <a routerLink="/listing-details"><h3>Closed Now</h3></a>
                  </div>

                  <div class="content">
                      <div class="content-profile">
                          <img
                              src="assets/img/place-area/place-area-profile5.png"
                              alt="Images"
                          />
                          <h3>By, Kelvin Sasi</h3>
                      </div>
                      <span
                          ><i class="flaticon-cursor"></i> 56 Street Square
                          Plaza, NJ, USA</span
                      >
                      <a routerLink="/listing-details"
                          ><h3>Iconic Cafe in Onterio</h3></a
                      >
                      <p>
                          Lorem ipsum dolor sit amet, consectetur quam quaerat
                          voluptatem.
                      </p>
                      <div class="content-tag">
                          <ul>
                              <li>
                                  <a routerLink="/"
                                      ><i class="flaticon-place"></i
                                  ></a>
                              </li>
                              <li>
                                  <a routerLink="/"
                                      ><i class="flaticon-like"></i
                                  ></a>
                              </li>
                              <li>
                                  <a routerLink="/"
                                      ><i class="flaticon-workflow"></i
                                  ></a>
                              </li>
                          </ul>
                          <h3 class="price"><a href="#">$300-600</a></h3>
                      </div>
                  </div>
              </div>
          </div>

          <div class="col-lg-4 col-md-6">
              <div class="place-card">
                  <a routerLink="/listing-details" class="place-images"
                      ><img
                          src="assets/img/place-area/place-area6.jpg"
                          alt="Images"
                  /></a>

                  <div class="rating">
                      <ul>
                          <li><a routerLink="/">5.0</a></li>
                          <li>
                              <span>39 Review</span>
                              <i class="bx bxs-star"></i>
                              <i class="bx bxs-star"></i>
                              <i class="bx bxs-star"></i>
                              <i class="bx bxs-star"></i>
                              <i class="bx bxs-star"></i>
                          </li>
                      </ul>
                  </div>

                  <div class="status-tag bg-color-green">
                      <a routerLink="/listing-details"><h3>Open Now</h3></a>
                  </div>

                  <div class="content">
                      <div class="content-profile">
                          <img
                              src="assets/img/place-area/place-area-profile6.png"
                              alt="Images"
                          />
                          <h3>By, Creiun Hitler</h3>
                      </div>
                      <span
                          ><i class="flaticon-cursor"></i> 34-42 Montgomery St
                          , NY, USA</span
                      >
                      <a routerLink="/listing-details"
                          ><h3>Kentorin Hotel</h3></a
                      >
                      <p>
                          Lorem ipsum dolor sit amet, consectetur quam quaerat
                          voluptatem.
                      </p>
                      <div class="content-tag">
                          <ul>
                              <li>
                                  <a routerLink="/"
                                      ><i class="flaticon-place"></i
                                  ></a>
                              </li>
                              <li>
                                  <a routerLink="/"
                                      ><i class="flaticon-like"></i
                                  ></a>
                              </li>
                              <li>
                                  <a routerLink="/"
                                      ><i class="flaticon-workflow"></i
                                  ></a>
                              </li>
                          </ul>
                          <h3 class="price"><a href="#">$400-800</a></h3>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>