import { Component, OnInit } from "@angular/core";
import { AppService } from "src/app/app.service";
import { cardNew } from "src/assets/class/cardNew";

@Component({
    selector: "app-home-one",
    templateUrl: "./home-one.component.html",
    styleUrls: ["./home-one.component.scss"],
})
export class HomeOneComponent implements OnInit {
    cardHit: cardNew[];

    constructor(public appService: AppService) {}

    ngOnInit(): void {
        this.appService.categoryThis = 2;
    }
}
