import { Component } from '@angular/core';

@Component({
  selector: 'app-process-area',
  templateUrl: './process-area.component.html',
  styleUrls: ['./process-area.component.scss']
})
export class ProcessAreaComponent {

}
