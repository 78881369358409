<div class="navbar-area">
    <div class="nav-two main-nav">
        <div class="container-fluid">
            <nav class="container-max navbar navbar-expand-md navbar-light">
                <!-- <a class="navbar-brand" routerLink="/"><img src="assets/img/logo/logo2.png" alt="Logo"></a> -->

                <div
                    class="collapse navbar-collapse mean-menu"
                    id="navbarSupportedContent"
                >
                    <ul class="navbar-nav m-auto">
                        <li class="nav-item nav-item-hide">
                            <a href="/" class="nav-link">Главная </a>
                        </li>
                        <li class="nav-item nav-item-hide">
                            <a
                            routerLink="/швейная-фабрика"
                            class="nav-link"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"
                            >Швейная фабрика</a
                        >
                        </li>
                        <li class="nav-item nav-item-hide">
                            <a
                            routerLink="/ателье-по-пошиву-одежды"
                            class="nav-link"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"
                            >Ателье</a
                        >
                        </li>
                        <li class="nav-item nav-item-hide">
                            <a
                            routerLink="/магазин-женской-одежды"
                            class="nav-link"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"
                            >Магазин</a
                        >
                        </li>
                        <li class="nav-item nav-item-hide">
                            <a
                            routerLink="/женская-одежда-оптом"
                            class="nav-link"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"
                            >Каталог</a
                        >
                        </li>
                        <li class="nav-item nav-item-hide">
                            <a
                                routerLink="/about"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >О нас</a
                            >
                        </li>
                        <li class="nav-item">
                            <a routerLink="/contact" class="nav-link"
                                >Контакты</a
                            >
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
        <div class="footer-widget menu-widged-hide">
            <ul class="social-link">
                <li>
                    <a href="https://vk.com/public211447112" target="_blank"
                        ><i class="bx bxl-vk"></i
                    ></a>
                </li>
                <li>
                    <a href="https://t.me/RonrocSale" target="_blank"
                        ><i class="bx bxl-telegram"></i
                    ></a>
                </li>
                <li>
                    <a href="https://wa.me/+79508684766" target="_blank"
                        ><i class="bx bxl-whatsapp"></i
                    ></a>
                </li>
            </ul>
        </div>
    </div>
</div>
