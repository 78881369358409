import { Component } from '@angular/core';

@Component({
  selector: 'app-place-area',
  templateUrl: './place-area.component.html',
  styleUrls: ['./place-area.component.scss']
})
export class PlaceAreaComponent {

}
