<app-navbar-style-two></app-navbar-style-two>

<div class="inner-banner inner-bg1">
    <div class="container">
        <div class="inner-title text-center">
            <h3>{{ appService.categoryMainThis?.naim }}</h3>
            <ul>
                <li><a href="index.html">RonRoc</a></li>
                <li><i class="bx bx-chevron-right"></i></li>
                <li>{{appService.categorySubThis?.naim}}</li>
            </ul>
        </div>
    </div>
</div>

<app-category-area></app-category-area>

<section class="city-area-two pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8">
                <div class="section-title">
                    <span>Cities</span>
                    <h2>Explore Best Cities In Area</h2>
                    <p>
                        Porem ipsum dolor sit ame consectetur adipisicing eli
                        sed usmod tempor incididunt
                    </p>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="city-btn">
                    <a routerLink="/" class="default-btn"
                        >View all places <i class="bx bx-plus"></i
                    ></a>
                </div>
            </div>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="city-card">
                    <a routerLink="/assets/img/city-area/city-area5.jpg"
                        ><img
                            src="assets/img/city-area/city-area5.jpg"
                            alt="Images"
                    /></a>
                    <div class="content">
                        <a routerLink="/"><h3>Fort Worth</h3></a>
                        <span>17 Locations</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="city-card">
                    <a routerLink="/"
                        ><img
                            src="assets/img/city-area/city-area6.jpg"
                            alt="Images"
                    /></a>
                    <div class="content">
                        <a routerLink="/"><h3>San Antonio</h3></a>
                        <span>13 Locations</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="city-card">
                    <a routerLink="/"
                        ><img
                            src="assets/img/city-area/city-area7.jpg"
                            alt="Images"
                    /></a>
                    <div class="content">
                        <a routerLink="/"><h3>Milwaukee</h3></a>
                        <span>13 Locations</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-7 col-md-6">
                <div class="city-card">
                    <a routerLink="/"
                        ><img
                            src="assets/img/city-area/city-area8.jpg"
                            alt="Images"
                    /></a>
                    <div class="content">
                        <a routerLink="/"><h3>United Kingdom</h3></a>
                        <span>30 Locations</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-md-6 offset-md-3 offset-lg-0">
                <div class="city-card">
                    <a routerLink="/"
                        ><img
                            src="assets/img/city-area/city-area9.jpg"
                            alt="Images"
                    /></a>
                    <div class="content">
                        <a routerLink="/"><h3>Turkey</h3></a>
                        <span>34 Locations</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer-style-two></app-footer-style-two>
