import { Injectable, OnInit } from "@angular/core";
import { cardNew } from "src/assets/class/cardNew";
import { Reviews } from "src/assets/class/reviews";
import { HttpClient } from "@angular/common/http";
import { categoryMain } from "src/assets/class/categoryMain";
import { BehaviorSubject, Observable } from "rxjs";
import { first, take } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class AppService {
    categoryThis: Number;

    host = "https://ronroc.ru:81/data";

    //host = "localhost:3000"

    categoryMain: categoryMain[];
    categoryMainThis: categoryMain;
    categorySub: [];

    categorySubFilter;
    categorySubThis;

    //cardNew: cardNew[];
    cardNew: BehaviorSubject<cardNew[]> = new BehaviorSubject<cardNew[]>([]);
    cardNew$: Observable<cardNew[]> = this.cardNew.asObservable();

    constructor(public http: HttpClient) {}

    getDataNewcard() {
        this.http
            .get(this.host + "/items/" + this.categoryThis)
            .subscribe((response: cardNew[]) => {
                this.addCardNew(response);
            });
    }

    addCardNew(card: cardNew[]) {
        this.cardNew$.pipe(take(1)).subscribe(() => {
            this.cardNew.next(card);
        });
    }

    cardNewFilter: cardNew[];
    reviews: Reviews[];
    cardHit: cardNew[];

    getCategorySubThis(id) {
        this.categorySubThis = this.categorySub.filter((item) => {
            return item["id"] == id;
        })[0];
    }

    getCategorySub() {
        this.categorySubFilter = this.categorySub.filter((item) => {
            return item["idMain"] == this.categoryThis;
        });
    }

    getCategoryMainThis() {
        this.categoryMainThis = this.categoryMain.filter((item) => {
            return item["id"] == this.categoryThis;
        })[0];
    }
}
