<footer class="footer-area footer-bg">
    <div class="footer-top">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-5">
                    <div class="newsletter-title">
                        <i class="flaticon-email"></i>
                        <h2>Don't Miss Our Update</h2>
                    </div>
                </div>

                <div class="col-lg-6 col-md-7">
                    <div class="newsletter-area">
                        <form class="newsletter-form">
                            <input type="email" class="form-control" placeholder="Your Email*" name="EMAIL" required autocomplete="off">
                            <button class="default-btn border-radius" type="submit">SUBSCRIBE NOW  </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="footer-middle pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-6">
                    <div class="footer-widget">
                        <a routerLink="/" class="logo"><img src="assets/img/logo/logo1.png" alt="Logo"></a>
                        <p>Pilar Financial Services, Inc. 200 Wood Avenue South, Ninth Floor Iselin, NJ 65432200 Wood Avenue South goinip</p>
                        <p>Pilar Financial Services, Inc. 200 Wood Avenue South </p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="footer-widget pl-5">
                        <h3>CONTACT INFO</h3>

                        <ul class="footer-contact-list">
                            <li><span>Monday - Friday :</span> 9 am to 6 pm</li> 
                            <li><span>Saturday - Sunday :</span> 9 am to 2 pm</li> 
                            <li><span>Phone :</span> <a href="tel:2151234567"> 215 - 123 - 4567</a></li> 
                            <li><span>Email :</span> <a href="mailto:info@pilar.com"> info@pilar.com</a></li> 
                        </ul>
                        <ul class="social-link">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                        </ul>
                    </div>
                </div>

                <div class="col-lg-5 col-md-8 offset-md-2 offset-lg-0">
                    <div class="footer-widget pl-5">
                        <h3>OUR LASTEST NEWS</h3>

                        <ul class="footer-news">
                            <li>
                                <div class="img">
                                    <a routerLink="/blog-details"><img src="assets/img/blog/footer-blog1.jpg" alt="Images"></a>
                                </div>
                                <div class="content">
                                    <h4>7 Mar 2020</h4>
                                    <span><a routerLink="/blog-details">Renovations that Add the Most Resale</a></span>
                                </div>
                            </li>
                            <li>
                                <div class="img">
                                    <a routerLink="/blog-details"><img src="assets/img/blog/footer-blog2.jpg" alt="Images"></a>
                                </div>
                                <div class="content">
                                    <h4>17 June 2020</h4>
                                    <span><a routerLink="/blog-details">Most Popular Visiting Palace In England</a></span>
                                </div>
                            </li>
                            <li>
                                <div class="img">
                                    <a routerLink="/blog-details"><img src="assets/img/blog/footer-blog3.jpg" alt="Images"></a>
                                </div>
                                <div class="content">
                                    <h4>12 April 2020</h4>
                                    <span><a routerLink="/blog-details">The Best Family Convenience In London  </a></span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="copy-right-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-8">
                <div class="copy-right-text">
                    <p>© Pilar is Proudly Owned by <a href="https://hibootstrap.com/" target="_blank">HiBootstrap</a></p>
                </div>
            </div>

            <div class="col-lg-4 col-md-4">
                <div class="copy-right-list">
                    <ul>
                        <li><a routerLink="/terms-condition" target="_blank">Terms of Use</a></li> 
                        <li><a routerLink="/privacy-policy" target="_blank">Privacy Policy</a></li> 
                        <li><a routerLink="/blog" target="_blank">Blog</a></li> 
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>